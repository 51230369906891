<template>
  <div class="dashboard-container">
    <div style="text-align: end">
      <h2 style="margin: 0">สวัสดี Staff, {{username}}</h2>
    </div>

    <h3>รอดำเนินการ</h3>
    <div class="table-dashboard">
      <!-- <div class="dashboard-content">
        <div class="list-container">
            <h5>ฝาก</h5>
            <table class="table-layout">
                <tr>
                    <td style="text-align: start">บัญชี</td>
                    <td style="width: 5rem">ยอด</td>
                    <td style="width: 3rem">สถานะ</td>
                </tr>
                <tr v-for="({ id, amount, status ,user: { bank } }, i) in deposit" :key="i.key" style="font-size: 12px">
                    <td style="text-align: start">{{bank}}</td>
                    <td>{{number_comma(amount)}}</td>
                    <td><button :disabled="on_update" @click="update_history(id, 'UserDeposit')" class="badge text-bg-warning">{{trx_status[status]}}</button></td>
                </tr>
            </table>
        </div>
        
      </div> -->
      <div class="dashboard-content">
            <div class="list-container">
                <h5>ถอน</h5>
                <table class="table-layout">
                    <tr>
                        <td style="text-align: start">บัญชี</td>
                        <td style="width: 5rem">ยอด</td>
                        <td colspan="2" style="width: 6rem">จัดการ</td>
                    </tr>
                    <tr v-for="({ id, amount ,user }, i) in withdraw" :key="i.key" style="font-size: 12px">
                        <td style="text-align: start">{{user.bank}} <a @click="check_game_play(user.id)" href="#">ตรวจสอบ</a></td>
                        <td>{{number_comma(amount)}}</td>
                        <td>
                          <button style="height: 1rem" :disabled="on_update" @click="update_history(id, 'UserWithdraw', 1)" class="badge text-bg-warning">อนุมัต</button>
                        </td>
                        <td>
                          <button style="height: 1rem" :disabled="on_update" @click="update_history(id, 'UserWithdraw', 2, amount, user.id)" class="badge text-bg-danger">ไม่อนุมัต</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="table-dashboard">
        <div class="dashboard-content">
        <div style="display: flex;align-items: center">
          <h3>Users</h3>
          <input style="margin: 0.5rem;color: black" v-model="search_user" placeholder="ค้นหา"/>
        </div>
        <AllUser
          :data_list="users"
          :actions="[
            { name: 'แก้ไขข้อมูล', icon: 'drive_file_rename_outline', action: update_user },
            { name: 'ล้างโปร', icon: 'clear', action: clear_promo }
          ]"
          :update_promo="update_promo"
          :promos="promos"
        />
      </div>
    </div>

    <div class="table-dashboard">
        <div class="dashboard-content">
          <div style="display: flex;align-items: center">
            <h3>Users</h3>
            <input style="margin: 0.5rem;color: black" v-model="search_user_promo" placeholder="ค้นหา"/>
          </div>
          <div class="list-container">
            <table style="width: 100%; text-align: center;table-layout: auto;white-space: nowrap;">
                <tr>
                  <td>เบอร์โทรศัพท์</td>
                  <td>บัญชี</td>
                  <td>โปรโมชั่น</td>
                  <td>จบโปร</td>
                </tr>
                <tr v-for="({ id, username, phone_number, promotion: { name } }, i) in promo_users" :key="i.key" style="font-size: 12px">
                    <td>{{phone_number}}</td>
                    <td>{{username}}</td>
                    <td><span>{{name}}</span></td>
                    <td>
                      <button @click="add_credit(id)" class="badge text-bg-warning">เติม Credit โปร</button>
                    </td>
                </tr>
            </table>
          </div>
        </div>
    </div>

    <div class="table-dashboard">
        <div class="dashboard-content">
        <h3>ประวัติทำรายการ</h3>
        <div class="list-container">
          <history
            :data_list="history"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StaffService from '../../services/StaffService'
import moment from 'moment'
import Swal from 'sweetalert2'
import axios from 'axios'
import AllUser from './components/allusers.vue'
import history from './components/history.vue'

export default {
  components: { AllUser, history },
  name: 'dashboard-staff',
  data () {
    return {
        username: '',
        search_user: null,
        last_value: null,
        deposit: [],
        withdraw: [],
        history: [],
        all_users: [],
        promos: [],
        get_history_interval: null,
        default_action: {
            Allow: 'อนุมัต',
            NotAllow: 'ไม่อนุมัติ',
            UserDeposit: 'ฝาก',
            UserWithdraw: 'ถอน',
            Update: 'แก้ไข'
        },
        on_update: false,
        trx_status: {
            0: 'รอ',
            1: 'อนุมัติ',
            2: 'ไม่อนุมัต'
        },
        my_ip: null,
        search_user_promo: null,
        agent_1_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTY2NzIyOTkwNywic3ViIjoiVXNlciJ9.l1clOQqz1DLaDshQ0latNwkh3cw2Lc5-V79K0JL5JJI'
    }
  },
  computed: {
    users() {
      return this.all_users.filter(({ username, phone_number }) => this.search_user? (username.toLowerCase().includes(this.search_user.toLowerCase()) || (phone_number.includes(this.search_user))):true)
    },
    promo_users () {
      return this.all_users
        .filter(({ username, phone_number }) => this.search_user_promo? (username.toLowerCase().includes(this.search_user_promo.toLowerCase()) || (phone_number.includes(this.search_user_promo))):true)
        .filter(({ promotion_id }) => [1,4].includes(promotion_id))
    }
  },
  methods: {
    async get_my_ip() {
      const { data } = await axios.get('https://www.cloudflare.com/cdn-cgi/trace')
      let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
      this.my_ip = data.match(ipRegex)[0];
    },
    async update_user(id) {
      Swal.fire({
        title: `แก้ไข USER ID: ${id}`,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'ปรับเครดิต',
        denyButtonText: `เปลี่ยนรหัส`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire({
            input: 'number',
            inputLabel: 'จำนวนเงิน',
            inputPlaceholder: 'จำนวนเงิน',
            showCancelButton: true,
          }).then(async (res) => {
            if (res.isConfirmed) {
              this.on_update = true
              StaffService.update_user(id, { amount: res.value, type: 'Update', ip: this.my_ip })
                .then(() => {
                  this.on_update = false
                })
            }
          })

        } else if (result.isDenied) {
          await Swal.fire({
            title: 'ตั้งรหัสผ่านใหม่',
            input: 'password'
          }).then((res) => {
            if (res.isConfirmed) {
              StaffService.update_user(id, { password: res.value, type: 'Password', ip: this.my_ip })
            }
          })
        }
      })
    },
    async add_credit(id) {
      const { value: amount } = await Swal.fire({
        input: 'number',
        inputLabel: 'จำนวนเงิน',
        inputPlaceholder: 'จำนวนเงิน'
      })

      if (amount) {
        await axios.post(`https://api.uday888.com/agent/add-credit`, { id, amount: Number(amount) }, {
          headers: { Authorization: `bearer ${this.agent_1_token}`}
        }).then(res => {
          console.log(res);
        })
      }

      this.update_promo(7, id)
    },
    async check_game_play(id) {
      const res = await StaffService.check_game_play(id)
      Swal.fire({
        title: '<strong>ประวัติการเล่น</strong>',
        html: `${this.create_table(res)}`
      })
    },
    create_table(string) {
      const table = string.map(({ created_at, gamecode, result: { type, amount } }) => {
        return `<tr><td>${created_at}</td><td>${gamecode}</td><td class="badge text-bg-${type == 'win'? 'success':'warning'}">${type}: ${amount.toFixed(2)}</td></tr>`
      })
      return `<table style="width: 100%">${table.toString().replaceAll(',','')}</table>`
    },
    async update_history(id, type, status, amount, user_id) {
      this.on_update = true
      const res = await StaffService.update_history(id, type, status)
      if (res) {
          this.on_update = false
          console.log(res);
          if (amount) {
            StaffService.update_user(user_id, { amount: amount, type: 'Refund', ip: this.my_ip })
          }
      }
    },
    show_history_action(action) {
        const value = action.split('-')
        const allow = value[0]
        const type = value[1]
        const user = value[2]
        const amount = value[3]

        return `${this.default_action[allow]} - (${user}) ${this.default_action[type]} ${amount} THB`
    },
    number_comma(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    async get_staff_profile() {
        const time_format = 'YYYY-MM-DD HH:mm:ss'
        const { username, deposit, withdraw, history } = await StaffService.profile()
        this.username = username
        this.deposit = deposit
        this.withdraw = withdraw
        this.history = history.sort((a,b) => moment(b.created_at,time_format).unix() - moment(a.created_at,time_format).unix())
    },
    async get_all_users() {
      const all_users = await StaffService.get_users()
      this.all_users = all_users
    },
    async get_promos() {
      const promos = await StaffService.get_promos()
      this.promos = promos
    },
    clear_promo(id) {
      console.log(id);
      this.update_promo(null, id)
    },
    async update_promo (promotion_id, user_id) {
      await StaffService.update_promos(promotion_id, user_id)
        .then(() => {
          this.get_all_users()
        })
        .catch((err) => {
          console.log(err);
        })
    },
    check_staff() {
        if (!localStorage.getItem('staff')) {
            this.$router.push('/')
        }
    },
    init_history_interval() {
        this.get_history_interval = setInterval(() => {
            this.get_staff_profile()
            this.get_all_users()
        },5000)
    }
  },
  async mounted () {
    this.check_staff()
    await this.get_staff_profile()
    this.init_history_interval()
    this.get_all_users()
    this.get_promos()
    this.get_my_ip()
  }
}
</script>

<style lang="less" scoped>
  h2, h3, h5 {
    color: #fce285;
  }
  button {
    height: 2rem;
    margin: 0;
    width: 100%;
  }
  button:disabled {
    display: none
  }
    .badge {
        cursor: pointer;
    }
    .table-layout {
        table-layout: fixed;
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
    }
  .regis-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      margin: 0.25rem
    }
    input::placeholder {
      color: #fce285
    }
  }
  .dashboard-container {
    padding: 0.5rem;
    flex-direction: column;
    display: flex;
  }
  .dashboard-content {
    width: 100%;
    padding: 0.5rem;
  }
  .table-dashboard {
    display: flex;
    justify-content: space-evenly;

    table {
      max-height: 20rem;
      padding: 0.5rem
    }

    .list-container {
      .table-text {
        text-align: center;
        font-size: 14px
      }
      padding: 0.5rem;
      height: 20rem;
      overflow-y: scroll;
      border: 1px solid #fce285;
      border-radius: 5px;
    }
  }
  .create-account {
    display:flex;
    justify-content: center;
    width: 100%;
    .login-input {
      display: flex;
      flex-direction: column;
    }
  }
</style>
