<template>
    <a-table :columns="[
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: 'เบอร์โทรศัพท์',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: 120
        },
        {
            title: 'ชื่อบัญชี',
            dataIndex: 'username',
            key: 'username',
            width: 200
        },
        {
            title: 'ยอดเงิน',
            key: 'balance',
            dataIndex: 'balance'
        },
        {
            title: 'Promotion',
            key: 'promotion_id',
            dataIndex: 'promotion_id',
            width: 200
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right'
        }
    ]" :data-source="data_list"
        :scroll="{ x: 300 }"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
            <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" @click.prevent>
                    จัดการ
                </a>
                <template #overlay>
                <a-menu>
                    <a-menu-item @click="action(record.id)" v-for="{ name, icon, action }, i in actions" :key="i">
                        <span style="font-size: 16px" class="material-symbols-outlined">{{icon}}</span>
                        <span>{{name}}</span>
                    </a-menu-item>
                </a-menu>
                </template>
            </a-dropdown>
        </template>
        <template v-else-if="column.key === 'promotion_id'">
            <select :disabled="record.promotion_id == 7" v-model="record.promotion_id" @change="update_promo(record.promotion_id, record.id)" style="width: 100%">
                <option :value="null">ไม่มี</option>
                <option :value="promo.id" v-for="promo in promos" :key="promo.id">{{promo.name}}</option>
            </select>
        </template>
      </template>
    </a-table>
  </template>
<script>
export default {
    props: {
        data_list: Array,
        actions: Array,
        promos: Array,
        update_promo: Function
    }
}
</script>

<style>
    select:disabled {
        background-color: #fce285;
        color: white
    }
    select {
        background-color: white;
        border:1px solid #f4f4f4;
        padding: 2px
    }
</style>