<template>
    <a-table :columns="[
        {
            title: 'วัน-เวลา',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'รายการ',
            dataIndex: 'action',
            key: 'action'
        }
    ]" :data-source="data_list"
    >
    </a-table>
  </template>
<script>
export default {
    props: {
        data_list: Array,
    }
}
</script>